<template>
  <main class="pt-5 pb-3">
    <div class="container">

      <div class="card memberStep">
        <div class="card-body">
          <div class="pb-4 text-center">
            <img src="@/assets/images/citizenship/whinchester.png" alt="Logo whinchester" height="60" />
          </div>
          <h2 class="mainTitleSize">Application for Dominica Citizenship</h2>
          <form-wizard ref="wizard" @on-complete="onComplete" step-size="xs" color="#0c3361" :start-index="currentStep" next-button-text="Save and Next">
            <tab-content title="" :before-change="validateFormOne">
              <firstStep ref="firstStepRef" />
            </tab-content>
            <tab-content title="" :before-change="validateFormTwo">
              <personalInformation ref="personalInformationRef" />
            </tab-content>
            <tab-content title="" :before-change="validateFormThree">
              <businessAndSource ref="businessAndSourceStepRef" />
            </tab-content>
            <tab-content title="" :before-change="validateFormFour">
              <InformationAbout ref="informationAboutRef" />
            </tab-content>
            <tab-content title="" :before-change="validateFormFive">
              <declarations ref="declarationsRef" />
            </tab-content>
            <tab-content title="" :before-change="validateFormSix">
              <Additional_information ref="additionalInformationRef" />
            </tab-content>
            <tab-content title="" :before-change="validateFormSeven">
              <Required_Step ref="requiredStepRef" />
            </tab-content>
            <tab-content title="" :before-change="validateFormEight">
              <Assistance_Form ref="assistanceFormRef" />
            </tab-content>
            <tab-content title="" :before-change="validateFormNine">
              <Undertakings_From ref="undertakingFormRef" />
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
  </main>
  <Footer></Footer>
</template>
  
<script>
import Footer from "@/components/footer/footer.vue";
import { FormWizard, TabContent } from "vue3-form-wizard";
import 'vue3-form-wizard/dist/style.css';
import axios from "axios";
import firstStep from '../sub-components/first_step.vue';
import personalInformation from '../sub-components/personal_information_step.vue';
import businessAndSource from '../sub-components/businessAndSource_step.vue';
import InformationAbout from '../sub-components/Information_about.vue';
import declarations from '../sub-components/declarations.vue';
import Additional_information from '../sub-components/Additional_information.vue';
import Required_Step from '../sub-components/Required_Step.vue';
import Assistance_Form from '../sub-components/Assistance_Form.vue';
import Undertakings_From from '../sub-components/Undertakings_From.vue';
import CryptoJS from 'crypto-js';

export default {

  data() {
    return {
      app_URL: process.env.VUE_APP_URL,
      showRadio: false,
      currentStep: 0,
      initialize: false
    }
  },

  components: {
    firstStep,
    FormWizard,
    TabContent,
    personalInformation,
    businessAndSource,
    InformationAbout,
    declarations,
    Additional_information,
    Required_Step,
    Assistance_Form,
    Undertakings_From,
    Footer
  },

  // watch: {
  //   '$route'() {
  //     this.currentStep = 
  // },
  created() {
    if (this.$route.params.id) {
      this.getStep();
    }
    this.getAuthenticatedUser();
    this.currentStep = parseInt(localStorage.getItem('currentStep'));
    if (this.$store.state.currentStep != null) {
      this.currentStep = parseInt(this.$store.state.currentStep)
    }

  },
  mounted() {
    if (this.$route.params.id) {
      this.getStep();
    }
    // this.$refs.wizard.changeTab(this.currentStep);
    this.getAuthenticatedUser();
    // Add an event listener for the beforeunload event
    this.currentStep = parseInt(localStorage.getItem('currentStep'));
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    if (this.$store.state.currentStep != null) {
      this.currentStep = parseInt(this.$store.state.currentStep)
    }
    console.log(this.currentStep)
    this.initialize = true
  },

  beforeMount() {
    if (this.$route.params.id) {
      this.getStep();
    }
    this.getAuthenticatedUser();
    // Remove the event listener when the component is destroyed
    this.currentStep = parseInt(localStorage.getItem('currentStep'));
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    if (this.$store.state.currentStep != null) {
      this.currentStep = parseInt(this.$store.state.currentStep)
    }
    this.initialize = true
  },
  methods: {
    handleBeforeUnload(event) {
      // You can perform any actions here before the tab/window is closed
      // For example, you can show a confirmation dialog
      event.returnValue = "Are you sure you want to leave this page?";
    },
    getStep() {
      axios.post(this.app_URL + "api/stepCheck", { disclosure_id: this.decode(this.$route.params.id) }).then(res => {
        localStorage.setItem('currentStep', res.data);
        this.currentStep = res.data;
      })
    },
    getAuthenticatedUser() {
      let data = new FormData();
      data.append('email', atob(decodeURIComponent(JSON.parse(localStorage.getItem('email')))));
      data.append('disclosure_id', this.decode(this.$route.params.id));
      axios.post(this.app_URL + 'api/getAuthenticatedUser', data)
        .then(res => {
          this.$store.state.authenticatedUserId = res.data.data.id;
          this.$store.state.disclosure_id = res.data.disclosure_id.id;
          this.$store.state.authenticatedUser = res.data.data;
          this.$store.state.forFamily = res.data.forFamily;
          this.currentStep = res.data.totalForms;
          localStorage.setItem('currentStep', this.currentStep);
        })
        .catch((error) => {
          console.log(error)
          // Handle errors
        });
    },
    onComplete() {
      this.$router.push({ path: '/payment' });
    },
    resetFields() {
      this.showRadio = false
    },
    validateFormOne() {
      const isValid = this.$refs.firstStepRef.validateForm();
      this.$refs.requiredStepRef.checkAge();
      this.$refs.personalInformationRef.getDisclosureForm();
      return isValid;
    },
    validateFormTwo() {
      const isValid = this.$refs.personalInformationRef.validatePersonalForm();
      return isValid;
    },

    validateFormThree() {
      const isValid = this.$refs.businessAndSourceStepRef.validateEmploymentForm();
      return isValid;
    },

    validateFormFour() {
      const isValid = this.$refs.informationAboutRef.validateInformationForm();
      return isValid;
    },
    validateFormFive() {
      const isValid = this.$refs.declarationsRef.validateDeclarationForm();
      return isValid;
    },
    validateFormSix() {
      const isValid = this.$refs.additionalInformationRef.validateAdditionalForm();
      return isValid;
    },
    validateFormSeven() {
      const isValid = this.$refs.requiredStepRef.validateRequiredStepForm();
      return isValid;
    },
    validateFormEight() {
      const isValid = this.$refs.assistanceFormRef.validateAssistanceForm();
      return isValid;
    },
    validateFormNine() {
      const isValid = this.$refs.undertakingFormRef.validateUndertakingForm();
      return isValid;
    },
    decode(id) {
      return decodeURIComponent(
        CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
          CryptoJS.enc.Utf8
        )
      );
    }

  }
}



</script>